import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { withTranslation } from 'react-i18next';

import doubleRoomimg from '../../../assets/img/room-slider/hotel-villa-antonio-orebic-double-room-cover.jpg';
import tripleRoomimg from '../../../assets/img/room-slider/hotel-villa-antonio-orebic-triple-room-cover.jpg';
import royalSuiteimg from '../../../assets/img/room-slider/hotel-villa-antonio-orebic-double-room-cover.jpg';
import presidentSuiteimg from '../../../assets/img/room-slider/hotel-villa-antonio-orebic-president-suite-cover.jpg';
import familySuiteimg from '../../../assets/img/room-slider/hotel-villa-antonio-orebic-family-suite-cover.jpg';



const featureroomPosts = [
  {
    photo: familySuiteimg,
    title: 'Family Suite',
    url: '/room-details/0',
    price: '€90',
    time: 'Night',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.',
  },
  {
    photo: royalSuiteimg,
    title: 'Royal Suite',
    url: '/room-details/1',
    price: '€100',
    time: 'Night',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.',
  },
  {
      photo: presidentSuiteimg,
      title: 'President Suite',
      url: '/room-details/2',
      price: '€160',
      time: 'Night',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.',
  },
  {
    photo: doubleRoomimg,
    title: 'Standard Double Room',
    url: '/room-details/3',
    price: '€60',
    time: 'Night',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.',
  },
  {
    photo: tripleRoomimg,
    title: 'Standard Triple Room',
    url: '/room-details/4',
    price: '€70',
    time: 'Night',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.',
  },
];
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-arrow next-arrow" onClick={onClick}><i className="fal fa-arrow-right"></i></div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-arrow prev-arrow" onClick={onClick}><i className="fal fa-arrow-left"></i></div>
  );
} 
class Roomslider extends Component {
  render() {
    const settings = {
      slidesToShow: 1,
				slidesToScroll: 1,
				fade: false,
				infinite: true,
				autoplay: false,
				autoplaySpeed: 4000,
				arrows: true,
				dots: false,
				centerMode: true,
        centerPadding: '28%',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
				responsive: [
					{
						breakpoint: 1600,
						settings: {
							centerPadding: '20%',
						},
					},
					{
						breakpoint: 992,
						settings: {
							centerPadding: '15%',
						},
					},
					{
						breakpoint: 768,
						settings: {
							centerPadding: '10%',
						},
					},
					{
						breakpoint: 576,
						settings: {
							centerPadding: '5%',
						},
					},
				],
  }; 
    return (
        <section className="room-slider bg-white pb-100 pt-115">
        <div className="container-fluid p-0">
          <div className="section-title mb-80 text-center">
            <span className="title-tag">Hotel villa Antonio</span>
            <h2>{this.props.t('rooms_suites')}</h2>
          </div>
          <Slider className="row rooms-slider-two justify-content-center" {...settings}>
          {featureroomPosts.map((item, i) => (
            <div key={i} className="col-lg-12">
              <div className="single-rooms-box">
                <div className="room-img">
                  <div className="img" style={{backgroundImage: "url("+ item.photo + ")"}}>
                  </div>
                </div>
                <ul className="icons">
                  <li><i className="fal fa-bed" /></li>
                  <li><i className="fal fa-wifi" /></li>
                  <li><i className="fal fa-car" /></li>
                  <li><i className="fal fa-coffee" /></li>
                  <li><i className="fal fa-concierge-bell" /></li>
                  <li><i className="fal fa-swimmer" /></li>
                  <li><i className="fal fa-compress-arrows-alt" /></li>
                </ul>
                <div className="room-desc">
                  <div className="row align-items-center">
                    <div className="col-sm-8">
                      <h3><Link to={item.url}>{item.title}</Link></h3>
                      
                    </div>
                    <div className="col-sm-4">
                      <div className="price">
                     {this.props.t('from')} {item.price}<span>/{item.time}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          </Slider>
        </div>
      </section>
      
    );
  }
}

export default withTranslation()(Roomslider);
