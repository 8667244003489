import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Slider from "react-slick";
import ReactWOW from 'react-wow'

import bannerimg1 from '../../../assets/img/banner/hotel-villa-antonio-orebic-front-photo.jpg';
import bannerimg2 from '../../../assets/img/banner/hotel-villa-antonio-orebic-pool-photo.jpg';


class Banner extends Component {

  
  render() {
    const settings = {
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      dots: false,
      fade: true,
      arrows: false,
  }; 

  const bannerPosts = [
    {
        photo: bannerimg2,
        tag: 'Hotel villa Antonio',
        taganimation: '.6s',
        title: this.props.t('banner_title1'), //{this.props.t('banner_title1')}
        titleanimation:'.9s',
        btn1:'take a tour',
        btn1animation:'1.1s',
        btn1url:'/room-details',
        btn2:'Learn More',
        btn2animation:'1.3s',
        btn2url:'/about',
    }
  ];
  
    return (
        <Slider className="banner-area banner-style-three" id="bannerSlider" {...settings}>
      {bannerPosts.map((item, i) => (
        <div key={i} className="single-banner d-flex align-items-center justify-content-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="banner-content">
                  <ReactWOW animation="fadeInDown" data-delay={item.taganimation}>
                  <span className="promo-tag">
                  {item.tag}
                  </span></ReactWOW>
                  <ReactWOW animation="fadeInLeft" data-delay={item.titleanimation}>
                  <h1 className="title">
                  {item.title}
                  </h1></ReactWOW>
                </div>
              </div>
            </div>
          </div>
          {/* banner bg */}
          <div className="banner-bg" style={{backgroundImage: "url("+ item.photo + ")"}} />
          <div className="banner-overly" />
        </div>
        ))}
      </Slider>
      
    );
  }
}

export default withTranslation()(Banner);
