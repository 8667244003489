import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactWOW from 'react-wow'
import { withTranslation } from 'react-i18next';


class Corefeature extends Component {
  render() {
    return (
        <section className="core-feature-section bg-white pt-115 pb-115">
        <div className="container">
          <div className="section-title text-center mb-50">
            <span className="title-tag"> hotel villa antonio </span>
            <h2>{ this.props.t('whywe')}</h2>
          </div>
          {/* Featre Loop */}
          <div className="row features-loop">
            <div className="col-lg-4 col-sm-6 order-1">
            <ReactWOW animation="fadeInLeft" data-wow-delay=".3s">
              <div className="feature-box">
                <div className="icon">
                  <i className="flaticon-swimming" />
                </div>
                <h3><Link to="#">{ this.props.t('swimming_pool')}</Link></h3>
                <p>
                { this.props.t('pool_icon')}
                </p>
                <span className="count">01</span>
              </div>
              </ReactWOW>
            </div>
            <div className="col-lg-4 col-sm-6 order-2">
            <ReactWOW animation="fadeInDown" data-wow-delay=".4s">
              <div className="feature-box">
                <div className="icon">
                  <i className="flaticon-car" />
                </div>
                <h3><Link to="#">{ this.props.t('free_parking')}</Link></h3>
                <p>
                { this.props.t('parking_icon')}
                </p>
                <span className="count">02</span>
              </div>
              </ReactWOW>
            </div>
            <div className="col-lg-4 col-sm-6 order-3 order-sm-4 order-lg-3">
            <ReactWOW animation="fadeInRight" data-wow-delay=".5s">
              <div className="feature-box">
                <div className="icon">
                  <i className="flaticon-location-pin" />
                </div>
                <h3><Link to="#">{ this.props.t('amazing_location')}</Link></h3>
                <p>
                { this.props.t('location_icon')}
                </p>
                <span className="count">03</span>
              </div>
              </ReactWOW>
            </div>
            <div className="col-lg-4 col-sm-6 order-4 order-sm-3 order-lg-4">
            <ReactWOW animation="fadeInLeft" data-wow-delay=".6s">
              <div className="feature-box">
                <div className="icon">
                  <i className="flaticon-breakfast" />
                </div>
                <h3><Link to="#">{ this.props.t('restaurant')}</Link></h3>
                <p>
                { this.props.t('restaurant_icon')}
                </p>
                <span className="count">04</span>
              </div>
              </ReactWOW>
            </div>
            <div className="col-lg-4 col-sm-6 order-5">
            <ReactWOW animation="fadeInUp" data-wow-delay=".7s">
              <div className="feature-box">
                <div className="icon">
                  <i className="flaticon-rating" />
                </div>
                <h3><Link to="#">{ this.props.t('high_ratings')}</Link></h3>
                <p>
                { this.props.t('high_ratings_icon')}
                </p>
                <span className="count">05</span>
              </div>
              </ReactWOW>
            </div>
            
            <div className="col-lg-4 col-sm-6 order-6">
            <ReactWOW animation="fadeInRight" data-wow-delay=".8s">
              <div className="feature-box">
                <div className="icon">
                  <i className="flaticon-discount" />
                </div>
                <h3><Link to="#">{ this.props.t('special_offers')}</Link></h3>
                <p>
                { this.props.t('special_offers_icon')}
                </p>
                <span className="count">06</span>
              </div>
              </ReactWOW>
            </div>
            
          </div>
        </div>
      </section>
      
    );
  }
}

export default withTranslation()(Corefeature);
