import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Headerthree from '../../components/layouts/Headerthree';
import Footertwo from '../../components/layouts/Footertwo';
import Breadcrumb from './components/Breadcrumb';
import About from './components/About'
import Textblock from '../../components/Textblock';
import image from "../../assets/img/banner/restaurant.jpg"


class Restaurant extends Component {
  render() {
    return (
      <div>
        <Headerthree />
        {/*====== BREADCRUMB PART START ======*/}
        <Breadcrumb/>
        {/*====== BREADCRUMB PART END ======*/}
        {/*====== RESTAURANT PART START ======*/}
        <section className="about-section pt-115 pb-115">
          <div className="container">
        <div className="section-title about-title text-center">
              <h2>{ this.props.t('restaurant_text')}</h2>
            </div>
            </div>
        </section>
        <Textblock image={image} text={""} description={this.props.t('restaurant_text1')} title={this.props.t('offer')}  />
        {/*====== MENU AREA START ======*/}
        <About />
        {/*====== MENU AREA END ======*/}
        <Footertwo />
      </div>

    );
  }
}

export default withTranslation()(Restaurant);
