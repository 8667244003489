import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Preloader from './components/layouts/Preloader';
import Homethree from './pages/HomePage/Homethree';
import About from './pages/About/About';
import Gallery from './pages/Gallery/Gallery';
import Restaurant from './pages/Restaurant/Restaurant';
import Roomdetails from './pages/RoomDetails/Roomdetails';
import Contact from './pages/Contact/Contact';

function App() {
  return (
      <Router>
         <Preloader />
        <Switch>
          <Route exact path='/' component={Homethree} />
          <Route path='/about' component={About} />
          <Route path='/gallery' component={Gallery} />
         <Route path='/restaurant' component={Restaurant} /> 
          <Route path='/room-details/:id' component={Roomdetails}   key={window.location.pathname}/>
          <Route path='/contact' component={Contact} />
        </Switch>
      </Router>
  );
}

export default App;
