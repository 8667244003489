import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import restaurant_cover from '../../../assets/img/restu/restorran_cover.jpg'
import { withTranslation } from 'react-i18next';


class Breadcrumb extends Component {
  render() {
    return (
      <section className="breadcrumb-area" style={{backgroundImage: 'url('+restaurant_cover+')'}}>
        <div className="container">
          <div className="breadcrumb-text">
            <span>HOTEL VILLA ANTONIO</span>
            <h2 className="page-title">{this.props.t('restaurant')}</h2>
            <ul className="breadcrumb-nav">
              <li><Link to="/">{this.props.t('home')}</Link></li>
              <li className="active">{this.props.t('restaurant')}</li>
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(Breadcrumb);
