import React, { Component } from 'react';
import * as emailjs from 'emailjs-com';
import { withTranslation } from 'react-i18next';

class Roomsidebar extends Component {

  constructor(props){
    super(props)
    this.state = {
      check_in: '',
      check_out: '',
      room: '',
      guest: 1,
      email: ''
    }
    this.handleChange = this.handleChange.bind(this)
  }

  getMaxGuests = (roomType) => {
    switch (roomType) {
      case 'Family Suite':
        return 4;
      case 'Royal Suite':
        return 4;
      case 'President Suite':
        return 5;
      case 'Standard Double Room':
        return 2;
      case 'Standard Triple Room':
        return 3;
      default:
        return 1; // Default to 1 guest
    }
  }

  generateGuestOptions = () => {
    const maxGuests = this.getMaxGuests(this.props.room);
    const options = [];
    for (let i = 1; i <= maxGuests; i++) {
      options.push(<option key={i} value={i}>{i}</option>);
    }
    return options;
  }
 

  handleSubmit = (e) => {
    e.preventDefault()

    const {check_in, check_out, room, guest, email } = this.state;

    let templateParams = {
      from_name: email,
      to_name: 'pansionvillaantonio@gmail.com',
      check_in: check_in,
      check_out: check_out,
      room: this.props.room,
      guest: guest
    }

// Booking Form
// Template ID:template_vbprb5x
    emailjs.send(
      'service_u0qwum5',
      'template_35ys785',
       templateParams,
      'XdX4TrqmTc1trTJao'

    )
    this.resetForm()
    alert("Your request is sent succeessfuly. We will contact you as soon as possible.")
  }

  resetForm =() => {
   this.setState({
    check_in: '',
    check_out: '',
    room: '',
    guest: 1,
    email: ''})
  }


  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }



  render() {
    return (
      <div className="room-booking-form">
        <h5 className="title">{this.props.t('check_availability')}</h5>
        <form onSubmit={this.handleSubmit}>
          <div className="input-group input-group-two left-icon mb-20">
            <label htmlFor="arrival-date">{this.props.t('check_in')}</label>
            <input required="true" type="date" placeholder="DD-MM-YYYY" name="check_in" id="arrival-date" value={this.state.check_in} onChange={this.handleChange}/>
          </div>
          <div className="input-group input-group-two left-icon mb-20">
            <label htmlFor="departure-date">{this.props.t('check_out')}</label>
            {/* <div className="icon"><i className="fal fa-calendar-alt" /></div> */}
            <input type="date" placeholder="30-6-2020" name="check_out" id="departure-date" value={this.state.check_out} onChange={this.handleChange} />
          </div>
          <div className="input-group input-group-two left-icon mb-20">
            <label htmlFor="room">{this.props.t('room')}</label>
            {/* <select name="room" id="room"> */}
              {/* <option onChange={this.handleChange} name="room" value={this.props.room}>{this.props.room}</option> */}
             <input value={this.props.room} placeholder={this.props.room} contentEditable={false} />
            {/* </select> */}
          </div>
          <div className="input-group input-group-two left-icon mb-20">
            <label htmlFor="departure-date">{this.props.t('guests')}</label>
            <select name="guest" className='pad-right-select-arrow' id="guest" value={this.state.guest} onChange={this.handleChange}>
            {this.generateGuestOptions()}
             </select>
          </div>
          <div className="input-group input-group-two left-icon mb-20">
            <label htmlFor="arrival-date">Email</label>
            <div className="icon"><i className="fal fa-mail-alt" /></div>
            <input type="text" placeholder="enter your email" name="email" id="arrival-date" value={this.state.email} onChange={this.handleChange} />
          </div>
          <div className="input-group">
            <button type="submit" className="main-btn btn-filled">{this.props.t('check_availability')}</button>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation()(Roomsidebar);
