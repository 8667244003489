import React, { Component } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ReactWOW from 'react-wow'
import bannerimg2 from '../../../assets/img/bg/hotel-villa-antonio-orebic-night-view.jpg';
import videoIcon from '../../../assets/img/icon/07.png';

class Videowrap extends Component {
  state = {
    open: false,
    };
    onOpenModal = () => {
    this.setState({ open: true });
    };
    
    onCloseModal = () => {
    this.setState({ open: false });
    };
    render() {
    const { open } = this.state;
    return (
        <section className="video-wrap full-section" style={{backgroundImage: `url(${bannerimg2})`}}>
            <ReactWOW animation="fadeInDown" data-wow-delay=".3s">
            <div  onClick={this.onOpenModal} className="popup-video">
            <img src={videoIcon} alt="video icon" />
            </div>
            </ReactWOW>
            <Modal open={open} onClose={this.onCloseModal} center classNames={{
                modal: 'video-modal',
                }}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/WjX_P0hJ-3k" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </Modal>
        </section>
    );
  }
}

export default Videowrap;
