import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {Modal, Alert} from 'react-bootstrap'
import * as emailjs from 'emailjs-com';

class Bookingform extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      check_in: '',
      check_out: '',
      room: 'Family Suite',
      guest: 1,
      email: '',
      roomOptions: [
        { name: 'Family Suite', maxGuests: 4 },
        { name: 'Royal Suite', maxGuests: 4 },
        { name: 'President Suite', maxGuests: 5 },
        { name: 'Standard Double Room', maxGuests: 2 },
        { name: 'Standard Triple Room', maxGuests: 3 }
      ]
      // showAlert: false
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleClose = () => {
    this.setState({showModal: false})
  }


  handleShow = () => {
    const defaultRoomOption = this.state.roomOptions.find(option => option.name === this.state.room);
    if (defaultRoomOption) {
      this.setState({ maxGuests: defaultRoomOption.maxGuests });
    }
    this.setState({ showModal: true });
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const {check_in, check_out, room, guest, email } = this.state;

    let templateParams = {
      from_name: email,
      to_name: 'pansionvillaantonio@gmail.com',
      check_in: check_in,
      check_out: check_out,
      room: room,
      guest: guest,
      
    }
    // Booking Form
// Template ID:template_vbprb5x
emailjs.send(
  'service_u0qwum5',
  'template_35ys785',
   templateParams,
  'XdX4TrqmTc1trTJao'

    )
    this.resetForm()
    alert("Your request is sent succeessfuly. We will contact you as soon as possible.")
  }

  resetForm =() => {
    this.setState({
     check_in: '',
     check_out: '',
     room: 'Family Suite',
     guest: 1,
     name: '',
     email: '',
    showModal: false    // showAlert: true
    })
   }

   handleChange(event) {
    if (event.target.name === 'room') {
      const selectedRoom = event.target.value;
      const maxGuests = this.state.roomOptions.find(option => option.name === selectedRoom)?.maxGuests || 1;
      this.setState({ 
        [event.target.name]: selectedRoom,
        guest: 1,
        maxGuests: maxGuests 
      });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

 
  renderModal() {
    return(
      <Modal
        show={this.state.showModal}
        onHide={this.handleClose}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.t('check_availability')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="contact-form">
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                    <div className="input-group mb-30">
                    <label htmlFor="arrival-date">{this.props.t('check_in')}</label>
            <input required="true" type="date" placeholder="DD-MM-YYYY" name="check_in" id="arrival-date" value={this.state.check_in} onChange={this.handleChange}/>
               </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-30">
                    <label htmlFor="departure-date">{this.props.t('check_out')}</label>
               <input required="true" type="date" placeholder="30-6-2020" name="check_out" id="departure-date" value={this.state.check_out} onChange={this.handleChange} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-30">
                    <label htmlFor="room">{this.props.t('room')}</label>
            <select name="room" className='pad-right-select-arrow-modal' id="room"  onChange={this.handleChange} value={this.state.room}>
            {this.state.roomOptions.map(option => (
                        <option key={option.name} value={option.name}>{option.name}</option>
                      ))}
            </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-30">
                    <label htmlFor="departure-date">{this.props.t('guests')}</label>
                      <select name="guest" className='pad-right-select-arrow-modal' id="guest" value={this.state.guest} onChange={this.handleChange}>
                      {[...Array(this.state.maxGuests)].map((_, index) => (
                        <option key={index + 1} value={index + 1}>{index + 1}</option>
                      ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group textarea mb-30">
                    <label htmlFor="arrival-date">Name</label>    
                      <input required="true" type="text" placeholder="Enter your name" name="name" id="name" value={this.state.name} onChange={this.handleChange} />

                    </div>
                  </div>

                  <div className="col-12">
                    <div className="input-group textarea mb-30">
                    <label htmlFor="arrival-date">Email</label>    
                      <input required="true" type="text" placeholder="Enter your email" name="email" id="email" value={this.state.email} onChange={this.handleChange} />

                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button type="submit" className="main-btn btn-filled">{this.props.t('check_availability')}</button>
                  </div>
                </div>
              </form>
            </div>
        </Modal.Body>
    </Modal>
    )
  }

  renderAlert() {
    return(
      <Alert variant="success">
          Your request is sent succeessfuly. We will contact you as soon as possible.
      </Alert>
    )
  }

  render() {
    return (
        <section className="booking-form booking-form-dark">
        <div className="container">
          <div className="booking-form-inner">
            <form onSubmit={(e) => {
              e.preventDefault()
              this.handleShow()
            }}>
              <div className="row align-items-end justify-content-center">
                <div className="col-lg-3 col-sm-6">
                  <div className="inputs-filed mt-30">
                    <label htmlFor="arrival-date">{ this.props.t('arrival_date')}</label>
                    <input type="date" placeholder="24th march 2020" name="check_in" id="arrival-date" value={this.state.check_in} onChange={this.handleChange} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="inputs-filed mt-30">
                    <label htmlFor="departure-date">{ this.props.t('departure_date')}</label>
                    <input type="date" placeholder="30th march 2020"name="check_out" id="departure-date" value={this.state.check_out} onChange={this.handleChange} />
                  </div>
                </div>
                {/* <div className="col-lg-3 col-md-6">
                  <div className="inputs-filed mt-30">
                    <label htmlFor="guests">{ this.props.t('guests')}</label>
                    <div className="nice-select">
                    <select name="guest" id="guest" value={this.state.guest} onChange={this.handleChange}>
                      <option value={0}>{ this.props.t('select')}</option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                     
                    </select>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-3 col-md-6">
                  <div className="inputs-filed mt-30">
                    <button onClick={this.handleShow} >{ this.props.t('check_availability')}</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* {this.renderAlert()} */}
        {this.renderModal()}
      </section>
      
    );
  }
}

export default withTranslation()(Bookingform);
