import React, { Component } from 'react'
import Breadcrumb from './components/Breadcrumb';
import Aboutpage from './components/About';
import Headerthree from '../../components/layouts/Headerthree';
import Footertwo from '../../components/layouts/Footertwo';

class About extends Component {
  render() {
    return (
      <div>
        <Headerthree />
        <Breadcrumb/>
        <Aboutpage/>
        <Footertwo />
      </div>
    );
  }
}

export default About;
