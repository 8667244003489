import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-component';
import ReactWOW from 'react-wow'
import { withTranslation } from 'react-i18next';

import image1 from '../../../assets/img/feature/Antonio_084.jpg'
import wine from '../../../assets/img/feature/008.jpg'
import image2 from '../../../assets/img/feature/01.jpg';
import image3 from '../../../assets/img/feature/24.jpg';


class About extends Component {
  render() {
    return (
        <section className="about-section pt-115 pb-115">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <ReactWOW animation="fadeInLeft" data-wow-delay=".3s">
            <div className="col-lg-6 col-md-10">
              <Masonry className="row about-features-boxes fetaure-masonary">
                <div className="col-sm-6">
                  <div className="single-feature-box">
                    <h4><Link to="#">  { this.props.t('wine')}</Link></h4>
                    <p>
                    { this.props.t('restaurant_text2')}
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="single-feature-box only-bg mt-30" style={{backgroundImage: 'url(' + image2 + ')'}}>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="single-feature-box only-bg mt-30" style={{backgroundImage: 'url(' + image3 + ')'}}>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="single-feature-box only-bg mt-30" style={{backgroundImage: 'url('+wine+')'}}>
                  </div>
                </div>
              </Masonry>
            </div>
            </ReactWOW>

          </div>
        </div>
        <div className="about-right-bottom">
          <div className="about-bottom-img">
            <img src={image1} alt="" height='1000'/>
          </div>
        </div>
      </section>
      
    );
  }
}

export default withTranslation()(About);
