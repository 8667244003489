import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/footer-logo.png';
import digisenceLogo from '../../assets/img/digisence-logo-no-bg.png';
import getAdriaticLogo from '../../assets/img/unnamed.png';
import { withTranslation } from 'react-i18next';

class Footertwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redText: false,
    };
  }
  componentDidMount() {
    window.addEventListener(
      'scroll',
      () => {
        this.setState({
          isTop: window.scrollY > 300,
        });
      },
      false
    );
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
  render() {
    const className = this.state.isTop ? 'active' : '';
    return (
      <div>
        {/*====== Back to Top ======*/}
        <Link
          to='#'
          className={`back-to-top ${className}`}
          id='backToTop'
          onClick={() => this.scrollToTop()}
        >
          <i className='fal fa-angle-double-up' />
        </Link>
        {/*====== FOOTER START ======*/}
        <footer className='footer-two'>
          <div className='footer-widget-area pt-100 pb-50'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-3 col-sm-6 order-1'>
                  {/* Site Info Widget */}
                  <div className='widget site-info-widget mb-50'>
                    <div className='footer-logo mb-50'>
                      <img src={logo} alt='Hotel Villa Antonio logo' />
                    </div>
                    <div className='social-links mt-40'>
                      {/* <Link to="#"><i className="fab fa-facebook-f" /></Link>
                      <Link to="#"><i className="fab fa-twitter" /></Link>
                      <Link to="#"><i className="fab fa-behance" /></Link>
                      <Link to="#"><i className="fab fa-linkedin" /></Link>
                      <Link to="#"><i className="fab fa-youtube" /></Link> */}
                      <p>Powered by:</p>
                      <img src={digisenceLogo} alt="Digisence Agency Logo" style={{width: "180px"}} />
                      <img
                        src={getAdriaticLogo}
                        alt='Korcula Stay logo'
                        style={{ width: '180px'}}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 order-3 order-lg-2'>
                  {/* Nav Widget */}
                  <div className='widget nav-widget mb-50'>
                    <div>
                      <h4 className='widget-title'>Links</h4>
                      <ul>
                        <li>
                          <Link to='/'>{this.props.t('home')}</Link>
                        </li>
                        <li>
                          <Link to='/about'>{this.props.t('about_us')}</Link>
                        </li>
                        <li>
                          <Link to='/restaurant'>
                            {this.props.t('restaurant')}
                          </Link>
                        </li>
                        <li>
                          <Link to='/gallery'>{this.props.t('gallery')}</Link>
                        </li>
                        <li>
                          <Link to='/contact'>{this.props.t('contact')}</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-sm-6 order-2 order-lg-3'>
                  {/* Contact Widget */}
                  <div className='widget contact-widget mb-50'>
                    <h4 className='widget-title'>{this.props.t('contact')}</h4>
                    <div className='contact-lists'>
                      <div className='contact-box'>
                        <div className='icon'>
                          <i className='flaticon-call' />
                        </div>
                        <div className='desc'>
                          <h6 className='title'>{this.props.t('telephon')}</h6>
                          +385 91 601 2239 <br />
                          +385 99 696 4190
                        </div>
                      </div>
                      <div className='contact-box'>
                        <div className='icon'>
                          <i className='flaticon-message' />
                        </div>
                        <div className='desc'>
                          <h6 className='title'>Email</h6>
                          <Link to='#'>pansionvillaantonio@gmail.com</Link>
                        </div>
                      </div>
                      <div className='contact-box'>
                        <div className='icon'>
                          <i className='flaticon-location-pin' />
                        </div>
                        <div className='desc'>
                          <h6 className='title'>
                            {this.props.t('our_location')}
                          </h6>
                          Postup 48, 20250, Orebić
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='copyright-area pt-30 pb-30'>
            <div className='container'>
              <div className='row align-items-center'>
                <div className='col-lg-6 col-md-8 order-2 order-md-1'>
                  <p className='copyright-text copyright-two'>
                    All Rights Reserved <a href='https://www.digisence.agency/'>Digisence</a> - 2024
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/*====== FOOTER END ======*/}
      </div>
    );
  }
}

export default withTranslation()(Footertwo);
