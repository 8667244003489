import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'react-responsive-modal/styles.css';
import { withTranslation } from 'react-i18next';
import ReactWOW from 'react-wow'
import restaurant from '../../../assets/img/banner/restaurant.jpg'
class About extends Component {
  state = {
    open: false,
    };
    onOpenModal = () => {
    this.setState({ open: true });
    };
    
    onCloseModal = () => {
    this.setState({ open: false });
    };
    render() {
    const { open } = this.state;
    return (
      <section className="about-section pt-115 pb-115">
          <div className="container">
            <div className="section-title about-title text-center">
              <span className="title-tag">since <span>1994</span></span>
              <h2>{ this.props.t('about_text')}</h2>
            </div>
            <ul className="about-features">
              <ReactWOW animation="fadeInUp" data-wow-delay=".3s">
              <li>
                <Link to="#">
                  <i className="flaticon-coffee" />
                  <i className="hover-icon flaticon-coffee" />
                  <span className="title">{this.props.t('breakfast')}</span>
                </Link>
              </li></ReactWOW>
              <ReactWOW animation="fadeInUp" data-wow-delay=".4s">
              <li>
                <Link to="#">
                  <i className="flaticon-air-freight" />
                  <i className="hover-icon flaticon-air-freight" />
                  <span className="title">{this.props.t('airport_pickup')}</span>
                </Link>
              </li></ReactWOW>
              <ReactWOW animation="fadeInUp" data-wow-delay=".5s">
              <li>
                <Link to="#">
                  <i className="flaticon-swimming" />
                  <i className="hover-icon flaticon-swimming" />
                  <span className="title">{this.props.t('swimming_pool')}</span>
                </Link>
              </li></ReactWOW>
              <ReactWOW animation="fadeInUp" data-wow-delay=".6s">
              <li>
                <Link to="#">
                  <i className="flaticon-hamburger" />
                  <i className="hover-icon flaticon-hamburger" />
                  <span className="title">{this.props.t('restaurant')}</span>
                </Link>
              </li></ReactWOW>
              <ReactWOW animation="fadeInUp" data-wow-delay=".7s">
              <li>
                <Link to="#">
                  <i className="flaticon-hotel" />
                  <i className="hover-icon flaticon-hotel" />
                  <span className="title">luxury room</span>
                </Link>
              </li></ReactWOW>
            </ul>
            <div className="about-text-box">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="about-img">
                    <img src={restaurant} alt="" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-text">
                    <span>{this.props.t('restaurant')}</span>
                    <h3>{this.props.t('explore_restaurant')} &amp;</h3>
                    <p>
                    {this.props.t('explore_restaurant_desc')}
                    </p>
                    <Link to="/restaurant" className="main-btn btn-filled">{this.props.t('tour')}</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
        <div className="section-title black-color text-center mb-50" style={{marginTop: "50px"}}>
              <h2>{this.props.t('our_location')}</h2>
            </div>
        <div className="mb-30" style={{height: '700px',width: '100%', marginTop: '30px'}}>
              <iframe  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23353.07422866637!2d17.21455653717889!3d42.975444979150346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134a52ff13549339%3A0x902bfc5245f585cc!2sHotel%20Villa%20Antonio%20d.o.o.!5e0!3m2!1sen!2shr!4v1611244042969!5m2!1sen!2shr" title="title" style={{width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center'}}/>
        </div>
        </div>
          </div>
        </section>
        
    );
  }
}

export default withTranslation()(About);
