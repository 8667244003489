import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactWOW from 'react-wow'

class Textblock extends Component {
    constructor(props) {
        super(props)
    }
  render() {
    return (
        <section className="text-block pt-115 pb-115">
            <div className="container">
                <ReactWOW animation="fadeInLeft" data-wow-delay=".3s">
            <div className="row align-items-center justify-content-center">
                <div className="col-lg-7">
                <div className="text-img text-center text-lg-left mb-small">
                    <img src={this.props.image} alt="Hotel villa Antonio swimming pool" />
                </div>
                </div>
                <ReactWOW animation="fadeInRight" data-wow-delay=".5s">
                <div className="col-lg-5 col-md-8 col-sm-10">
                <div className="block-text">
                    <div className="section-title mb-20">
                    <span className="title-tag">{this.props.text}</span>
                    <h2>{this.props.title}</h2>
                    </div>
                    <p className="fs-20">
                   { this.props.description}
                    </p>
                    
                </div>
                </div>
                </ReactWOW>
            </div>
            </ReactWOW>
            </div>
        </section>
        
    );
  }
}

export default withTranslation()(Textblock);
