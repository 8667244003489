import React from 'react'
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export const LanguageSelector = () => {
    const { t } = useTranslation();
    return (
        <div className="d-md-flex align-items-center justify-content-between">
        <select name="languages" id="langs" className='pad-right-select-arrow' onChange={(e)=> i18next.changeLanguage(e.target.value)}>
        <option value="" selected disabled hidden>{t("select_language")}</option>
          <option value="hr">Hrvatski</option>
          <option value="en">English</option>
          <option value="de">Deutsch</option> 
        </select>
        </div>  
    )
}
