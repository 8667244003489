import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import $ from 'jquery';
import {findDOMNode } from 'react-dom'
import logo from "../../assets/img/logo.png"
import {LanguageSelector} from "../helper/LanguageSelector"
import { withTranslation } from 'react-i18next';







class Headerthree extends Component {

  navigationmenu = [
    {
        id: 1,
        linkText: this.props.t('home'),
        link: '/',
        child: false,
        submenu: [] 
    },
    {
      
      id: 2,
      linkText: 'Rooms & Suites',
      // link: '/room-list',
      child: true,
      submenu:[
        {
          id: 21,
          link:'/room-details/0',
          linkText:'Family Suites',
        },
        {
          id: 22,
          link:'/room-details/1',
          linkText:'Royal Suite',
        },
        {
          id: 23,
          linkText:'President Suite',
          link:'/room-details/2',
        },
        {
          id: 24,
          linkText:'Standard Double',
          link:'/room-details/3',
        },
        {
          id: 25,
          link:'/room-details/4',
          linkText:'Standard Triple',
        },
      ]
      
  },
  {
      
    id: 3,
    linkText: 'Restaurant',
    child: false,
    link: '/restaurant'
    
  },
    {
      id: 4,
      link: '/about',
      linkText: "About Us",
      child: false,
    },
  
  {
    id: 5,
    linkText: 'Gallery',
    link: '/gallery',
    child: false,
  },
    {
      id: 6,
      link: '/contact',
      linkText: 'Contact',
      child: false,
    }
  ]

  constructor(props) {
    super(props);
    this.state = {
       redText: false
    };
    this.addClass = this.addClass.bind(this);
    this.removeClass = this.removeClass.bind(this);
    this.removeAll = this.removeAll.bind(this);
  }

   
  addClass() {
    this.setState({
      redText:true
    });
  }
  
  removeClass() {
    this.setState({
      redText:false
    });
  }
  removeAll() {
    this.setState({
      redText:false
    });
  }
  componentDidMount() {
    window.addEventListener('resize', () => {
        this.setState({
            isMobile: window.innerWidth < 1020
        });
    }, false);
    window.addEventListener('load', () => {
      this.setState({
          isMobile: window.innerWidth < 1020
      });
  }, false);
  }
  handleToggle = () => {
    const el = findDOMNode(this.refs.toggle);
    const els = findDOMNode(this.refs.addClass);
    $(el).slideToggle();
    $(els).toggleClass('active');
  }
  navToggle = () => {
    const nv = findDOMNode(this.refs.navmenu);
    const nvb = findDOMNode(this.refs.navbtn);
    $(nv).toggleClass('menu-on');
    $(nvb).toggleClass('active');
  }
  removenavToggle = () => {
    const nv = findDOMNode(this.refs.navmenu);
    const nvb = findDOMNode(this.refs.navbtn);
    $(nv).removeClass('menu-on');
    $(nvb).removeClass('active');
  }
  
getNextSibling = function (elem, selector) {

	// Get the next sibling element
	var sibling = elem.nextElementSibling;

	// If there's no selector, return the first sibling
	if (!selector) return sibling;

	// If the sibling matches our selector, use it
	// If not, jump to the next sibling and continue the loop
	while (sibling) {
		if (sibling.matches(selector)) return sibling;
		sibling = sibling.nextElementSibling
	}

}

triggerChild = (e) => {
  let subMenu = '';

  subMenu = ( this.getNextSibling(e.target, '.submenu') !== undefined ) ? this.getNextSibling(e.target, '.submenu') : null;

  if(subMenu !== null && subMenu !== undefined && subMenu !== ''){
    subMenu.classList = subMenu.classList.contains('open') ? 'submenu' : 'submenu open';
  }
}

checkNavType(id) {
  switch(id){
    case 1:
        return this.props.t('home');
    case 2:
        return this.props.t('rooms_suites');
    case 3:
        return  this.props.t('restaurant');
    case 4:
        return  this.props.t('about_us');
    case 5:
        return  this.props.t('gallery');
    case 6:
          return this.props.t('contact');
        default:
          return 0;
  }
}


  render() {
    const className = this.state.isMobile ? 'breakpoint-on' : '';
    return (
      
      <div>
        {/*====== HEADER START ======*/}
        <header className="header-three">
          <div className="header-top">
            <div className="container container-custom-three">              
            </div>
          </div>
          <div className="main-menu-area sticky-header">
            <div className="container container-custom-three">
              <div className={`nav-container d-flex align-items-center justify-content-between ${className}`}>
                {/* Site Logo */}
                <div className="site-logo">
                  <Link to="/"><img src={logo} style={{height: '125px'}} alt="" /></Link>
                </div>
                {/* Main Menu */}
                <div className="nav-menu d-lg-flex align-items-center justify-content-between" ref="navmenu">
                  {/* Navbar Close Icon */}
                  <div className="navbar-close" onClick={this.removenavToggle}>
                    <div className="cross-wrap"><span className="top" /><span className="bottom" /></div>
                  </div>
                  {/* Mneu Items */}
                  <div className="menu-items">
                  <ul>
                  {this.navigationmenu.length > 0 ? this.navigationmenu.map((item, i) => (
                      <li key={i} className={` ${item.child ? 'menu-item-has-children' : 'aaaaaaaaaaa'} `} onClick={this.triggerChild}>
                      {item.child ? <Link onClick={e => e.preventDefault()} to="/"> {this.checkNavType(item.id)} </Link> : <Link to={item.link}> {this.checkNavType(item.id)}  </Link> }
                          {item.child ?
                              <ul className="submenu" role="menu">
                                  {item.submenu.map((sub_item, i) => (
                                      <li key={i} className={`${sub_item.child ? 'menu-item-has-child' : ''} `}>
                                      {sub_item.child ? <Link onClick={e => e.preventDefault()} to="/"> {sub_item.linkText}  </Link> : <Link to={sub_item.link}> {sub_item.linkText}  </Link> }
                                          {sub_item.third_menu ?
                                              <ul className="submenu">
                                                  {sub_item.third_menu.map((third_item, i) =>  (
                                                   
                                                      <li key={i} ><Link
                                                          to={third_item.link}>{third_item.linkText}</Link>
                                                      </li>
                                                  ))}
                                              </ul> : null}
                                      </li>
                                  ))}
                              </ul>
                              : null
                          }
                      </li>
                  )) : null}
                  </ul>
                  </div>
                </div>
                {/* navbar right content */}
                <div className="menu-right-buttons">
                  {/* Log in icon */}
                  <div className="login-btn">
                  <LanguageSelector/>
                  </div>
                  {/* search btton */}
                  {/* Off canvas Toggle */}
                  <div className="toggle" onClick={this.addClass}>
                    <Link to="#" id="offCanvasBtn"><i className="fal fa-bars" /></Link>
                  </div>
                  {/* Navbar Toggler */}
                  <div className="navbar-toggler" onClick={this.navToggle}  ref="navbtn">
                    <span /><span /><span />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/*====== HEADER END ======*/}
        {/*====== OFF CANVAS START ======*/}
        <div className={classNames("offcanvas-wrapper", {"show-offcanvas":this.state.redText})}>
        <div className={classNames("offcanvas-overly", {"show-overly":this.state.redText})}  onClick={this.removeAll}/>
          <div className="offcanvas-widget">
            <Link to="#" className="offcanvas-close" onClick={this.removeClass}><i className="fal fa-times" /></Link>
            {/* About Widget */}
            <div className="widget about-widget">
              <h5 className="widget-title">{this.props.t('about_us')}</h5>
              <p>
              {this.props.t('hotel_description1')}
              </p>
            </div>
          </div>
        </div>
        {/*====== OFF CANVAS END ======*/}
      </div>
    );
  }
}

export default withTranslation()(Headerthree);
