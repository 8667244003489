import React, { Component } from 'react'
import Roomsidebar from './components/Roomsidebar';
import { Link } from 'react-router-dom';
import Roomslider from './components/Roomslider1';
import Headerthree from '../../components/layouts/Headerthree';
import Footertwo from '../../components/layouts/Footertwo';
import {familySuiteImages, presidentSuiteImages, royalSuiteImages, standardDoubleImages, standardTripleImages} from '../../components/helper/images'

import doubleRoomimg from '../../assets/img/room-slider/hotel-villa-antonio-orebic-double-room-cover.jpg';
import tripleRoomimg from '../../assets/img/room-slider/hotel-villa-antonio-orebic-triple-room-cover.jpg';
import familySuiteimg from '../../assets/img/room-slider/hotel-villa-antonio-orebic-family-suite-cover.jpg';
import royalSuiteimg from '../../assets/img/room-slider/hotel-villa-antonio-orebic-double-room-cover.jpg';
import presidentSuiteimg from '../../assets/img/room-slider/hotel-villa-antonio-orebic-president-suite-cover.jpg'
import { withTranslation } from 'react-i18next';

//napraviti objekt za svaku sobu i ubaciti slike u njega
const roomimgPosts = [
  {
    photo: presidentSuiteimg,
  },
  {
    photo: familySuiteimg,
  },
  {
    photo: royalSuiteimg,
  },
  {
    photo: doubleRoomimg,
  },
  {
    photo: tripleRoomimg,
  },
];



//const roomlistPosts =[]

class Roomdetails extends Component {
   roomlistPosts = [
    {
        photo: familySuiteimg,
        title: 'Family Suite',
        url: '/room-details',
        guest: 4,
        price: '€110',
        time: 'Night',
        btn: 'Booking Now',
        desc: this.props.t('family_suite_details'),
        roomingPosts: familySuiteImages,
    },
    {
        photo: royalSuiteimg,
        title: 'Royal Suite',
        url: '/room-details',
        guest: 4,
        price: '€120',
        time: 'Night',
        btn: 'Booking Now',
        desc: this.props.t('royal_suite_details'),
        roomingPosts: royalSuiteImages,
      },
    {
      photo: presidentSuiteimg,
      title: 'President Suite',
      url: '/room-details',
      guest: 5,
      price: '€180',
      time: 'Night',
      btn: 'Booking Now',
      desc: this.props.t('president_suite_details'),
      roomingPosts: presidentSuiteImages,
    },
    {
      photo: doubleRoomimg,
      title: 'Standard Double Room',
      url: '/room-details',
      guest:4,
      price: '€80',
      time: 'Night',
      btn: 'Booking Now',
      desc: this.props.t('double_room_details'),
      roomingPosts: standardDoubleImages,
    },
    {
      photo: tripleRoomimg,
      title: 'Standard Triple Room',
      url: '/room-details',
      guest: 4,
      price: '€90',
      time: 'Night',
      btn: 'Booking Now',
      desc: this.props.t('triple_room_details'),
      roomingPosts: standardTripleImages,
    },
   
  ];
  
  
  constructor(props){
    super(props) 
  
    let lastQueryParam = window.location.href.split("/").pop();
    this.state = {
      param: [],
      nav1: null,
    }


  }

  checkRoomType(roomName) {
    switch(roomName){
      case "Family Suite":
          return this.props.t('family_suite_details');
      case "Royal Suite":
          return this.props.t('royal_suite_details');
      case "President Suite":
          return this.props.t('president_suite_details');
      case "Standard Double Room":
          return this.props.t('double_room_details');
      case "Standard Triple Room":
          return this.props.t('triple_room_details');
          default:
            return 0;
    }
  }

  getData() {
    let lastQueryParam = window.location.href.split("/").pop();
    this.setState({
      param:this.roomlistPosts[lastQueryParam]
    })
     
  }

  componentDidMount() {
    this.getData()
    let lastQueryParam = window.location.href.split("/").pop();
  }

  componentDidUpdate(prevProps,prevState) {
    const locationChanged =  this.props.location ;
    if(locationChanged !== prevProps.location) this.getData()
  // INCORRECT, will *always* be false because history is mutable.
  
  }


  render() {

   
    return (
      <div>
        <Headerthree />
        {/*====== BREADCRUMB PART START ======*/}
        <section className="breadcrumb-area" style={{backgroundImage:`url(${this.state.param.photo})`}}>
          <div className="container">
            <div className="breadcrumb-text">
              <h2 className="page-title">{this.props.t('room_details')} {this.state.p}</h2>
              <ul className="breadcrumb-nav">
                <li><Link to="/">Home</Link></li>
                <li className="active">Rooms</li>
              </ul>
            </div>
          </div>
        </section>
        {/*====== BREADCRUMB PART END ======*/}
        {/*====== ROOM-DETAILS START ======*/}
        <section className="room-details pt-120 pb-90">
          <div className="container">
            <div className="row">
              {/* details */}
              <div className="col-lg-8">
                <div className="deatils-box">
                  <div className="title-wrap">
                    <div className="title">
                      <div className="room-cat">hotel villa antonio</div>
                      <h2>{`${this.state.param.title}`}</h2>
                    </div>
                    <div className="price">
                      {this.props.t('from')} {this.state.param.price}<span>/{this.props.t('night')}</span>
                    </div>
                  </div>
                  <div className="thumb">
                    <img src="assets/img/room/details.jpg" alt="" />
                  </div>
                  <p>
                    {this.checkRoomType(this.state.param.title)}
                  </p>
                  <div className="room-fearures clearfix mt-60 mb-60">
                    <h3 className="subtitle">{this.props.t('amenities')}</h3>
                    <ul className="room-fearures-list">
                      <li><i className="fal fa-bath" /> {this.props.t('air_conditioner')}</li>
                      <li><i className="fal fa-wifi" />{this.props.t('wifi')}</li>
                      <li><i className="fal fa-cut" />{this.props.t('breakfast')}</li>
                      <li><i className="fal fa-broom" />{this.props.t('cleaning')}</li>
                      <li><i className="fal fa-shower" />{this.props.t('shower')}</li>
                      <li><i className="fal fa-headphones-alt" />{this.props.t('online_support')}</li>
                      <li><i className="fal fa-sea" />{this.props.t('swimming_pool')}</li>

                    </ul>
                  </div>
                
                  <div className="room-rules clearfix mb-60">
                    <h3 className="subtitle">{this.props.t('house_rules')}</h3>
                    <ul className="room-rules-list">
                      <li>{this.props.t('check_in_time')}</li>
                      <li>{this.props.t('smoking')}</li>
                      <li>{this.props.t('party')}</li>
                      <li>{this.props.t('children')}</li>
                      <li>{this.props.t('pets')}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <Roomsidebar room={this.state.param.title} guest={this.state.param.guest}  />
              </div>
            </div>
          </div>
        </section>

         {/*====== ROOM SLIDER START ====== */}
         <Roomslider roomimgPosts={this.roomlistPosts[window.location.href.split("/").pop()].roomingPosts} />
        {/*====== ROOM SLIDER END ======*/}
        {/*====== ROOM-DETAILS END ======*/}
        <Footertwo />
      </div>

    );
  }
}

export default withTranslation()(Roomdetails);
