import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import image from '../../../assets/img/banner/hotel-villa-antonio-orebic-pool-photo.jpg'
import { withTranslation } from 'react-i18next';
class Breadcrumb extends Component {
  render() {
    return (
      <section className="breadcrumb-area" style={{backgroundImage: "url(" + image + ")"}}>
          <div className="container">
            <div className="breadcrumb-text">
              <span>Hotel Villa Antonio</span>
              <h2 className="page-title">{this.props.t('about_us')}</h2>
              <ul className="breadcrumb-nav">
                <li><Link to="/">{this.props.t('home')}</Link></li>
                <li className="active">{this.props.t('about_us')}</li>
              </ul>
            </div>
          </div>
        </section>
    );
  }
}

export default withTranslation()(Breadcrumb);
