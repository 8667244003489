//family suite images
import img1 from "../../assets/img/familysuiteimgs/family-suite-img1.jpg"  
import img2 from "../../assets/img/familysuiteimgs/family-suite-img2.jpg"
import img3 from "../../assets/img/familysuiteimgs/family-suite-img3.jpg"
import img4 from "../../assets/img/familysuiteimgs/family-suite-img4.jpg"
import img5 from "../../assets/img/familysuiteimgs/family-suite-img5.jpg"
import img6 from "../../assets/img/familysuiteimgs/family-suite-img7.jpg"
import img7 from "../../assets/img/familysuiteimgs/family-suite-img9.jpg"

//president suite images
import presImg from "../../assets/img/presidentsuiteimg/president-suite-img.jpg"
import presImg1 from "../../assets/img/presidentsuiteimg/president-suite-img1.jpg"
import presImg2 from "../../assets/img/presidentsuiteimg/president-suite-img2.jpg"
import presImg3 from "../../assets/img/presidentsuiteimg/president-suite-img3.jpg"
import presImg4 from "../../assets/img/presidentsuiteimg/president-suite-img4.jpg"
import presImg5 from "../../assets/img/presidentsuiteimg/president-suite-img5.jpg"
import presImg6 from "../../assets/img/presidentsuiteimg/president-suite-img6.jpg"
import presImg7 from "../../assets/img/presidentsuiteimg/president-suite-img7.jpg"
import presImg8 from "../../assets/img/presidentsuiteimg/president-suite-img8.jpg"
import presImg9 from "../../assets/img/presidentsuiteimg/president-suite-img9.jpg"

//royal suite images

import roysImg from "../../assets/img/royalsuiteimg/royal-suite-img.jpg"
import roysImg1 from "../../assets/img/royalsuiteimg/royal-suite-img1.jpg"
import roysImg2 from "../../assets/img/royalsuiteimg/royal-suite-img2.jpg"
import roysImg3 from "../../assets/img/royalsuiteimg/royal-suite-img3.jpg"
import roysImg4 from "../../assets/img/royalsuiteimg/royal-suite-img4.jpg"
import roysImg5 from "../../assets/img/royalsuiteimg/royal-suite-img5.jpg"
import roysImg6 from "../../assets/img/royalsuiteimg/royal-suite-img6.jpg"

//standard double images
import doubImg from "../../assets/img/standarddoubleimg/double-standard-img.jpg"
import doubImg1 from "../../assets/img/standarddoubleimg/double-standard-img1.jpg"
import doubImg2 from "../../assets/img/standarddoubleimg/double-standard-img2.jpg"
import doubImg3 from "../../assets/img/standarddoubleimg/double-standard-img3.jpg"
import doubImg4 from "../../assets/img/standarddoubleimg/double-standard-img4.jpg"
import doubImg5 from "../../assets/img/standarddoubleimg/double-standard-img5.jpg"
import doubImg6 from "../../assets/img/standarddoubleimg/double-standard-img6.jpg"
import doubImg7 from "../../assets/img/standarddoubleimg/double-standard-img7.jpg"
import doubImg8 from "../../assets/img/standarddoubleimg/double-standard-img8.jpg"

// standard triple images 
import tripImg  from "../../assets/img/standardtripleimg/triple-standard-img.jpg"
import tripImg1 from "../../assets/img/standardtripleimg/triple-standard-img1.jpg"
import tripImg2 from "../../assets/img/standardtripleimg/triple-standard-img2.jpg"
import tripImg3 from "../../assets/img/standardtripleimg/triple-standard-img3.jpg"
import tripImg4 from "../../assets/img/standardtripleimg/triple-standard-img4.jpg"
import tripImg5 from "../../assets/img/standardtripleimg/triple-standard-img5.jpg"
import tripImg6 from "../../assets/img/standardtripleimg/triple-standard-img6.jpg"

//showcase images
import showImg  from "../../assets/img/showcaseimg/showcase-img.jpg"
import showImg1  from "../../assets/img/showcaseimg/showcase-img1.jpg"
import showImg2  from "../../assets/img/showcaseimg/showcase-img2.jpg"
import showImg3  from "../../assets/img/showcaseimg/showcase-img3.jpg"
import showImg4  from "../../assets/img/showcaseimg/showcase-img4.jpg"
import showImg5 from "../../assets/img/showcaseimg/showcase-img5.jpg"
import showImg6  from "../../assets/img/showcaseimg/showcase-img6.jpg"
import showImg7  from "../../assets/img/showcaseimg/showcase-img7.jpg"
import showImg8  from "../../assets/img/showcaseimg/showcase-img8.jpg"
import showImg9  from "../../assets/img/showcaseimg/showcase-img9.jpg"
import showImg10 from "../../assets/img/restu/restorran_cover.jpg"
import showImg11 from "../../assets/img/banner/restaurant.jpg"
import showImg12 from "../../assets/img/feature/Antonio_084.jpg"





//family suite images
export const familySuiteImages = [
    {
        photo: img1,
        id: 1
    },
    {
        photo: img2,
        id: 2}
        ,
    {
        photo: img3,
        id: 3
    },
    {
        photo: img4,
        id: 4
    },
    {
        photo: img5,
        id: 5
    },
    {
        photo: img6,
        id: 6
    },
    {
        photo: img7,
        id: 7
    }
]
//president suite images
export const presidentSuiteImages = [
    {
        photo: presImg
    },
    {
        photo: presImg1
    },
    {
        photo: presImg2
    },
    {
        photo: presImg3
    },
    {
        photo: presImg4
    },
    {
        photo: presImg5
    },
    {
        photo: presImg6
    },
    {
        photo: presImg7
    },
    {
        photo: presImg8
    },
    {
        photo: presImg9
    }
]

// royal suite images
export const royalSuiteImages = [
    {
        photo: roysImg1
    },
    {
        photo: roysImg2
    },
    {
        photo: roysImg3
    },
    {
        photo: roysImg4
    },
    {
        photo: roysImg5
    },
    {
        photo: roysImg6
    },
    {
        photo: roysImg
    }
]

//standard double images
export const standardDoubleImages = [
    {
        photo: doubImg
    },
    {
        photo: doubImg1
    },
    {
        photo: doubImg2
    },
    {
        photo: doubImg3
    },
    {
        photo: doubImg4
    },
    {
        photo: doubImg5
    },
    {
        photo: doubImg6
    },
    {
        photo: doubImg7
    },
    {
        photo: doubImg8
    }
]

//standard double images
export const standardTripleImages = [
    {
        photo: tripImg
    },
    {
        photo: tripImg1
    },
    {
        photo: tripImg2
    },
    {
        photo: tripImg3
    },
    {
        photo: tripImg4
    },
    {
        photo: tripImg5
    },
    {
        photo: tripImg6
    }
]

//showcase images
export const showcaseImages = [
    {
        photo: showImg
    },
    {
        photo: showImg1
    },
    {
        photo: showImg2
    },
    {
        photo: showImg3
    },
    {
        photo: showImg4
    },
    {
        photo: showImg5
    },
    {
        photo: showImg6
    },
    {
        photo: showImg7
    },
    {
        photo: showImg8
    },
    {
        photo: showImg9
    },
    {
        photo: showImg10
    },
    {
        photo: showImg11
    },
    {
        photo: showImg12
    }
]
