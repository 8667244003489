import React, { Component } from 'react'
import Headerthree from '../../components/layouts/Headerthree';
import Footertwo from '../../components/layouts/Footertwo';
import { Link } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import { familySuiteImages, presidentSuiteImages, royalSuiteImages, showcaseImages, standardDoubleImages, standardTripleImages } from '../../components/helper/images';
import aboutUsImg from "../../assets/img/banner/hotel-villa-antonio-orebic-front-photo.jpg"
import { withTranslation } from 'react-i18next'
import { SRLWrapper } from "simple-react-lightbox";
import Img from "react-cool-img";


class Gallery extends Component {
  constructor(props){
    super(props)
   
  }

 
  render() {
    return (
      <div>
        <Headerthree />
        {/*====== BREADCRUMB PART START ======*/}
        <section className="breadcrumb-area" style={{backgroundImage: 'url('+ aboutUsImg + ')'}}>
          <div className="container">
            <div className="breadcrumb-text">
              <span>Hotel Villa Antonio</span>
              <h2 className="page-title">{this.props.t('gallery')}</h2>
              <ul className="breadcrumb-nav">
                <li><Link to="/">{this.props.t('home')}</Link></li>
                <li className="active">{this.props.t('gallery')}</li>
              </ul>
            </div>
          </div>
        </section>
        {/*====== BREADCRUMB PART END ======*/}
        {/*====== GALLERY START ======*/}
        <section className="room-type-section pt-115 pb-115">
        <Tab.Container defaultActiveKey="show" mountOnEnter={true} >
            <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 ">
              <Nav variant="pills" className="room-filter room-type-section nav nav-pills justify-content-center justify-content-lg-end" style={{paddingTop: '50px'
            }}>
              
               <Nav.Item>
                <Nav.Link eventKey="show">Outside</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                <Nav.Link eventKey="family">Family Suite</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                <Nav.Link eventKey="royal">Royal Suite</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                <Nav.Link eventKey="president">President Suite</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                <Nav.Link eventKey="double">Standard Double Room</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                <Nav.Link eventKey="triple">Standard Triple Room</Nav.Link>
                </Nav.Item>

                
                </Nav>
                </div>
            </div>

      <Tab.Content className="mt-65">
        <Tab.Pane eventKey="family">
              <section className="gallery-wrappper pt-120 pb-120">
                 <div className="container">
                  <div className="gallery-loop">
                    {familySuiteImages.map((item, i) => (
                    <SRLWrapper>
                     <div className="single-gallery-image" key={item.id} > 
                       <img
                          
                          style={{ backgroundColor: "grey", width: "480", height: "320" }}
                          src={item.photo}
                        />
                     </div>
                     </SRLWrapper>       

                      ))}
                     </div> 
                   </div>
               </section>
        </Tab.Pane>

        <Tab.Pane eventKey="royal">
        <SRLWrapper >
        <section className="gallery-wrappper pt-120 pb-120">
          <div className="container">
            <div className="gallery-loop">
            {royalSuiteImages.map((item, i) => (
              <div className="single-gallery-image"key={i}>
                  <img
                        style={{ backgroundColor: "grey", width: "480", height: "320" }}
                        src={item.photo}
                        alt=""  
                        />                  </div>
              ))}
            </div>
          </div>
        </section>
        </SRLWrapper>

        </Tab.Pane>
        <Tab.Pane eventKey="president">
        <SRLWrapper >
        <section className="gallery-wrappper pt-120 pb-120">
          <div className="container">
            <div className="gallery-loop">
            {presidentSuiteImages.map((item, i) => (
              <div className="single-gallery-image"key={i}>
  <img
                        style={{ backgroundColor: "grey", width: "480", height: "320" }}
                        src={item.photo}
                        alt=""  
                        />              </div>
              ))}
            </div>
          </div>
        </section>
        </SRLWrapper>
        </Tab.Pane>

        <Tab.Pane eventKey="double">
        <SRLWrapper >
        <section className="gallery-wrappper pt-120 pb-120">
          <div className="container">
            <div className="gallery-loop">
            {standardDoubleImages.map((item, i) => (
              <div className="single-gallery-image"key={i}>
                    <img
                        style={{ backgroundColor: "grey", width: "480", height: "320" }}
                        src={item.photo}
                        alt=""  
                        />              </div>
              ))}
            </div>
          </div>
        </section>
        </SRLWrapper>
        </Tab.Pane>

        <Tab.Pane eventKey="triple">
        <SRLWrapper >
        <section className="gallery-wrappper pt-120 pb-120">
          <div className="container">
            <div className="gallery-loop">
            {standardTripleImages.map((item, i) => (
              <div className="single-gallery-image"  key={i}>
                {/* <Link to={item.photo} className="popup-image"> */}
                <img
                        style={{ backgroundColor: "grey", width: "480", height: "320" }}
                        src={item.photo}
                        alt=""  
                        />                {/* </Link> */}
              </div>
              ))}
            </div>
          </div>
        </section>
        </SRLWrapper>
        </Tab.Pane>
        
        <Tab.Pane eventKey="show">
        <SRLWrapper >
        <section className="gallery-wrappper pt-120 pb-120">
          <div className="container">
            <div className="gallery-loop">
            {showcaseImages.map((item, i) => (
              <div className="single-gallery-image" key={i} >
                {/* <Link to={item.photo} className="popup-image"> */}
                    <img
                       style={{ backgroundColor: "grey", width: "480", height: "320" }}
                       src={item.photo}
                       alt=""  
                    />                {/* </Link> */}
              </div>
              ))}
            </div>
          </div>
        </section>
        </SRLWrapper>
        </Tab.Pane>
        </Tab.Content>
        </div>
        </Tab.Container>
        </section>
        {/*====== GALLERY END ======*/}
        <Footertwo />
      </div>

    );
  }
}

export default withTranslation()(Gallery);
