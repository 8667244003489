import React, { Component } from 'react'
import Headerthree from '../../components/layouts/Headerthree';
import Mainbanner from './components/Banner';
import Bookingform from './components/Bookingform';
import Textblock from '../../components/Textblock';
import Roomslider from './components/Roomslider';
import Videowrap from './components/Videowrap';
import Corefeature from './components/Corefeature';
import Footertwo from '../../components/layouts/Footertwo';
import image from "../../assets/img/text-block/06.jpg"
import { withTranslation } from 'react-i18next';


class Homethree extends Component {
  render() {
    return (
      <div>
          <Headerthree />
          {/*====== BANNER PART START ======*/}
          <Mainbanner />
          {/*====== BANNER PART ENDS ======*/}
          {/*====== BOOKING FORM START ======*/}
          <Bookingform/>
          {/*====== BOOKING FORM END ======*/}
          {/*====== ABOUT SECTION START ======*/}
          <Textblock image={image} text={this.props.t('explore')} description={this.props.t('hotel_description1')} title={"Hotel Villa Antonio"} />
          {/*====== ABOUT SECTION END ======*/}
          {/*====== ROOM SLIDER START ======*/}
          <Roomslider/>
          {/*====== ROOM SLIDER END ======*/}
          {/*====== TEXT BLOCK START ======*/}
          <Videowrap/>
          {/*====== TEXT BLOCK END ======*/}
          {/*====== CORE FEATURES START ======*/}
          <Corefeature/>
          {/*====== CORE FEATURES END ======*/}
          
          <Footertwo/>
        </div>

    );
  }
}

export default withTranslation()(Homethree);
