import React, { Component } from 'react'
import Headerthree from '../../components/layouts/Headerthree';
import Footertwo from '../../components/layouts/Footertwo';
import { Link } from 'react-router-dom';
import * as emailjs from 'emailjs-com'
import aboutUsImg from "../../assets/img/banner/hotel-villa-antonio-orebic-front-photo.jpg"
import { withTranslation } from 'react-i18next';


class Contact extends Component {

  constructor(props){
    super(props)
    this.state = {
      name: '',
      email: '',
      number: '',
      subject: '',
      message: ''
    }

    this.handleChange = this.handleChange.bind(this);

  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, number, subject, message } = this.state;
    
    let templateParams = {
      from_name: email,
      to_name: 'pansionvillaantonio@gmail.com',
      number: number,
      name: name,
      subject: subject,
      message: message
    }

    emailjs.send(
      'service_u0qwum5',
      'template_r82t12m',
       templateParams,
      'XdX4TrqmTc1trTJao'
    )

    this.resetForm()
    alert("Your request is sent succeessfuly. We will contact you as soon as possible.")

  }

  resetForm = () => {
    this.setState({
      name: '',
      email: '',
      number: '',
      subject: '',
      message: ''
    })
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }


  render() {
    return (
      <div>
        <Headerthree />
        {/*====== BREADCRUMB PART START ======*/}
        <section className="breadcrumb-area" style={{backgroundImage: 'url(' + aboutUsImg +')'}}>
          <div className="container">
            <div className="breadcrumb-text">
              <span>Hotel Villa Antonio</span>
              <h2 className="page-title">{this.props.t('contact')}</h2>
              <ul className="breadcrumb-nav">
                <li><Link to="/">{this.props.t('home')}</Link></li>
                <li className="active">{this.props.t('contact')}</li>
              </ul>
            </div>
          </div>
        </section>
        {/*====== BREADCRUMB PART END ======*/}
        {/*====== CONTACT PART START ======*/}
        <section className="contact-part pt-115 pb-115">
          <div className="container">
            {/* Contact Info */}
            <div className="contact-info">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-sm-6 col-10">
                  <div className="info-box">
                    <div className="icon">
                      <i className="flaticon-home" />
                    </div>
                    <div className="desc">
                      <h4>{this.props.t('our_location')}</h4>
                      <p>Postup 48, 20250, Orebić</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-10">
                  <div className="info-box">
                    <div className="icon">
                      <i className="flaticon-phone" />
                    </div>
                    <div className="desc">
                      <h4>{this.props.t('phone_number')}</h4>
                      <p> +385 91 601 2239 <br/>
                        +385 99 696 4190</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-10">
                  <div className="info-box">
                    <div className="icon">
                      <i className="flaticon-message" />
                    </div>
                    <div className="desc">
                      <h4>Email Address</h4>
                      <p> pansionvillaantonio@gmail.com <br /></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Contact Mapts
            <div className="contact-maps mb-30">
              <iframe src="https://maps.google.com/maps?q=university%20of%20san%20francisco&t=&z=13&ie=UTF8&iwloc=&output=embed" title="title" />
            </div> */}
            {/* Contact Form */}
            <div className="contact-form">
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                    <div className="input-group mb-30">
                      <span className="icon"><i className="far fa-user" /></span>
                      <input
                        type="text"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                        placeholder={this.props.t('enter_name')}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-30">
                      <span className="icon"><i className="far fa-envelope" /></span>
                      <input
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        placeholder={this.props.t('enter_email')}
                        required={true}
                         />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-30">
                      <span className="icon"><i className="far fa-phone" /></span>
                      <input 
                        type="number"
                        name="number" 
                        value={this.state.number}
                        onChange={this.handleChange}
                        required={true}
                        placeholder= {this.props.t('enter_phone')}/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-30">
                      <span className="icon"><i className="far fa-book" /></span>
                      <input
                       type="text"
                       name="subject"
                       value={this.state.subject}
                       onChange={this.handleChange}
                       required={true}
                       placeholder={this.props.t('enter_subject')} />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group textarea mb-30">
                      <span className="icon"><i className="far fa-pen" /></span>
                      <input 
                        type="textarea"
                        name="message"
                        value={this.state.message}
                        onChange={this.handleChange}
                        placeholder={this.props.t('enter_message')}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button type="submit" className="main-btn btn-filled">{this.props.t('send')}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        {/*====== CONTACT PART END ======*/}
        <Footertwo />
      </div>

    );
  }
}

export default withTranslation()(Contact);
